.search-bar-container-enter {
  width: 0;
}

.search-bar-container-enter-active {
  width: 20vw;
  transition: width 200ms;
}

.search-bar-container-exit {
  width: 200px;
}

.search-bar-container-enter-done{
  width: 20vw;
  margin-left: 10px;
}

.search-bar-container-exit-active {
  width: 0;
  transition: width 200ms;
}
